import React, { Component } from "react";
import { auth } from "../../firebase";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import Button from "../../components/button/Button";
import { Fade } from "react-reveal";
import "./SubscriptionsComponent.css";
import { greeting, subscriptionsPageData } from "../../portfolio.js";

const plan1Data = subscriptionsPageData.plan1;
const plan2Data = subscriptionsPageData.plan2;

class Subscriptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      isSigned: false,
    };
  }

  userIsSigned() {
    this.unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user });
        this.setState({ isSigned: true });
      } else {
        this.setState({ user: null });
        this.setState({ isSigned: false });
      }
    });
  }

  render() {
    const theme = this.props.theme;
    return (
      <div className="subscriptions-main">
        <Header theme={theme} />
        <div className="basic-contact">
          <Fade bottom duration={1000} distance="40px" className="fade-div">
            <div className="contact-heading-div">
              <div className="contact-heading-text-div">
                <h1
                  className="contact-heading-text"
                  style={{ color: theme.text }}
                >
                  {plan1Data["title"]}
                </h1>
                <p
                  className="contact-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {plan1Data["description"]}
                </p>
                <div className="resume-btn-div">
                  <Button
                    text="Subscribe Now"
                    newTab={true}
                    href={
                      this.state.isSigned ? greeting.githubProfile : "/signin"
                    }
                    theme={theme}
                  />
                </div>
              </div>
              <div className="contact-heading-text-div">
                <h1
                  className="contact-heading-text"
                  style={{ color: theme.text }}
                >
                  {plan2Data["title"]}
                </h1>
                <p
                  className="contact-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {plan2Data["description"]}
                </p>
                <div className="resume-btn-div">
                  <Button
                    text="Subscribe Now"
                    newTab={true}
                    href={
                      this.state.isSigned ? greeting.githubProfile : "/signin"
                    }
                    theme={theme}
                  />
                </div>
              </div>
            </div>
          </Fade>
        </div>
        <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Subscriptions;
