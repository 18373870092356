import React, { Component } from "react";
import { withTranslation } from "react-i18next"; // Import withTranslation HOC
import "./Skills.css";
import SoftwareSkill from "../../components/softwareSkills/SoftwareSkill";
import { Fade } from "react-reveal";
import Button from "../../components/button/Button";
import CreativityImg from "./CreativityImg";
import FullStackImg from "./FullStackImg";
import TechnologyImg from "./TechnologyImg";
import TeachingImg from "./TeachingImg";
import WorkspaceImg from "./WorkspaceImg";

function GetSkillSvg(props) {
  if (props.fileName === "CreativityImg")
    return <CreativityImg theme={props.theme} />;
  else if (props.fileName === "FullStackImg")
    return <FullStackImg theme={props.theme} />;
  else if (props.fileName === "TechnologyImg")
    return <TechnologyImg theme={props.theme} />;
  else if (props.fileName === "TeachingImg")
    return <TeachingImg theme={props.theme} />;
  return <WorkspaceImg theme={props.theme} />;
}

class SkillSection extends Component {
  render() {
    const { t } = this.props; // Access t function from props
    const theme = this.props.theme;

    // Extract skills from translation
    const skills = t("skills", { returnObjects: true });

    // Log skills to see its structure (for debugging purposes)
    console.log("skills:", skills);

    // Check if skills is an array
    if (!Array.isArray(skills)) {
      return <div>Error: skills is not an array</div>;
    }

    return (
      <div>
        {skills.map((skill, index) => {
          return (
            <div className="skills-main-div" key={index}>
              <div className="row_left">
                <Fade left duration={2000}>
                  <div className="skills-image-div">
                    <img
                      alt=""
                      style={{ width: "75%" }}
                      src={require(`../../assets/images/${skill.image_path}`)}
                    ></img>
                    {/* <GetSkillSvg fileName={skill.fileName} theme={theme} /> */}
                  </div>
                </Fade>
              </div>

              <div className="row_right">
                <div className="skills-text-div">
                  <Fade right duration={1000}>
                    <h1
                      className="skills-heading"
                      style={{ color: theme.text }}
                    >
                      {skill.title}
                    </h1>
                  </Fade>
                  {/* <Fade right duration={1500}>
                    <SoftwareSkill logos={skill.softwareSkills} />
                  </Fade> */}
                  <Fade right duration={2000}>
                    <div>
                      {skill.skills.map((skillSentence, idx) => {
                        return (
                          <p
                            className="subTitle skills-text"
                            style={{ color: theme.secondaryText }}
                            key={idx}
                          >
                            {skillSentence}
                          </p>
                        );
                      })}
                    </div>
                    <div className="resume-btn-div">
                      <Button
                        text={t("learn_more")}
                        newTab={false}
                        href={skill.link}
                        theme={theme}
                      />
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

// Wrap the component with withTranslation to provide the t function
export default withTranslation()(SkillSection);
