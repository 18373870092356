import React, { Component } from "react";
import Header from "../../components/header/Header";
import Greeting from "../../containers/greeting/Greeting";
import Skills from "../../containers/skills/Skills";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
// import EventPage from "../../components/eventPage/EventPage";
import { Helmet } from "react-helmet-async";

class Home extends Component {
  render() {
    return (
      <div>
        <Header theme={this.props.theme} />
        <Helmet>
          <title>coLab Makerspace</title>
          <meta
            name="description"
            content="Το coLab Makerspace αποτελεί έναν χώρο τεχνολογίας, σχεδιασμένο ειδικά για τις ανάγκες του κάθε maker. Διαθέτοντας ένα φιλόξενο Coworking Space, ένα  Open Lab εξοπλισμένο για κάθε ανάγκη και με πλήθος εκπαιδευτικών προγραμμάτων και events κάθε εβδομάδα, ήρθε για να γίνει ο επόμενος σου προορισμός."
          />
          <meta
            name="keywords"
            content="coLab, STEAM Πάτρα, Ρομποτική Πάτρα, Εκπαιδευτική Ρομποτική, 3D printing, Makerspace, coworking space, Αναγνωστήριο, Patras"
          />
          <link rel="canonical" href="/home" />
        </Helmet>
        <Greeting theme={this.props.theme} />
        {/* <EventPage theme={this.props.theme} /> */}
        <Skills theme={this.props.theme} />
        <Footer theme={this.props.theme} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Home;
