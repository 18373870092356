import React, { useState, useEffect } from "react";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { FaQuoteRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import "./Slider.css";

function Slider() {
  const { t } = useTranslation();
  const [index, setIndex] = useState(0);

  const people = [
    {
      id: 1,
      image: t("image_Anat"),
      name: t("name_Anat"),
      title: t("title_Anat"),
      quote: t("quote_Anat"),
    },
    {
      id: 2,
      image: t("image_Tzouv"),
      name: t("name_Tzouv"),
      title: t("title_Tzouv"),
      quote: t("quote_Tzouv"),
    },
    {
      id: 3,
      image: t("image_Kout"),
      name: t("name_Kout"),
      title: t("title_Kout"),
      quote: t("quote_Kout"),
    },
    {
      id: 4,
      image: t("image_Thanop"),
      name: t("name_Thanop"),
      title: t("title_Thanop"),
      quote: t("quote_Thanop"),
    },
    // {
    //   id: 5,
    //   image: t("image_Nikolo"),
    //   name: t("name_Nikolo"),
    //   title: t("title_Nikolo"),
    //   quote: t("quote_Nikolo"),
    // },
  ];

  useEffect(() => {
    const lastIndex = people.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index, people]);

  useEffect(() => {
    let slider = setInterval(() => {
      setIndex(index + 1);
    }, 5000);
    return () => clearInterval(slider);
  }, [index]);

  return (
    <section className="section">
      <div className="title">
        <h2>{t("team_title")}</h2>
      </div>
      <div className="section-center">
        {people.map((person, personIndex) => {
          const { id, image, name, title, quote } = person;
          let position = "nextSlide";
          if (personIndex === index) {
            position = "activeSlide";
          }
          if (
            personIndex === index - 1 ||
            (index === 0 && personIndex === people.length - 1)
          ) {
            position = "lastSlide";
          }
          return (
            <article key={id} className={position}>
              <img
                src={require(`../../assets/images/${image}`)}
                alt={name}
                className="person-img"
              />
              <h4>{name}</h4>
              <p className="title">{title}</p>
              <p className="text">{quote}</p>
              <FaQuoteRight className="icon" />
            </article>
          );
        })}
        <button className="prev" onClick={() => setIndex(index - 1)}>
          <FiChevronLeft />
        </button>
        <button className="next" onClick={() => setIndex(index + 1)}>
          <FiChevronRight />
        </button>
      </div>
    </section>
  );
}

export default Slider;
