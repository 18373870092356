import React, { Component } from "react";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import { Helmet } from "react-helmet";
import Header from "../../components/header/Header";
import DOMPurify from "dompurify";
import { Fade } from "react-reveal";

class ProjectPage extends Component {
  state = {
    project: null,
    loading: true,
  };

  componentDidMount() {
    const projectId = this.props.match.params.projectId;
    this.fetchProjectDetails(projectId);
  }

  fetchProjectDetails = (projectId) => {
    const apiUrl = `https://blogspot.colab-makerspace.gr/wp-json/wp/v2/new/${projectId}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          project: {
            title: data.title.rendered,
            imageUrl: data.acf.embed,
            description: data.acf.description,
            date: data.date,
          },
          loading: false,
        });
      })
      .catch((error) => {
        console.error("Error fetching project details:", error);
        this.setState({ loading: false });
      });
  };

  render() {
    const theme = this.props.theme;
    const { project, loading } = this.state;
    const cleanDescription = project
      ? DOMPurify.sanitize(project.description)
      : "";
    return (
      <div className="projects-main">
        <Header theme={theme} />
        <Helmet>
          <title>{project ? project.title : "Φόρτωση..."}</title>
          <meta
            name="description"
            content={project ? project.description : "Φόρτωση..."}
          />
          {/* Other meta tags */}
        </Helmet>
        {loading ? (
          <div className="projects-heading-text">Φόρτωση..</div>
        ) : (
          <div className="project-parent-div">
            <Fade bottom duration={2000} distance="40px">
              <h1
                style={{ color: theme.text }}
                className="repo-cards-heading-text"
              >
                {project.title}
              </h1>
            </Fade>
            {project && (
              <div className="project-main-div">
                <img
                  className="project-main-img"
                  src={project.imageUrl}
                  alt={project.title}
                />
                <h3>{project.title}</h3>
                <div
                  style={{ whiteSpace: "pre-wrap", textAlign: "left" }}
                  dangerouslySetInnerHTML={{ __html: cleanDescription }}
                ></div>
              </div>
            )}
          </div>
        )}
        <Footer theme={theme} onToggle={this.props.onToggle} />
        <TopButton theme={theme} />
      </div>
    );
  }
}

export default ProjectPage;
