// src/LanguageSwitcher.js
import React from "react";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div style={{ position: "absolute", top: 10, right: 10 }}>
      <button
        onClick={() => changeLanguage("en")}
        style={{
          border: "none",
          background: "none",
          cursor: "pointer",
          padding: 0,
          marginRight: 10,
        }}
      >
        <img
          src="/icons/us.png"
          alt="English"
          style={{ width: "30px", height: "20px" }}
        />
      </button>
      <button
        onClick={() => changeLanguage("el")}
        style={{
          border: "none",
          background: "none",
          cursor: "pointer",
          padding: 0,
        }}
      >
        <img
          src="/icons/gr.png"
          alt="Greek"
          style={{ width: "30px", height: "20px" }}
        />
      </button>
    </div>
  );
};

export default LanguageSwitcher;
