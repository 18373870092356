import React, { useState, useEffect } from "react";
import "./imageOverlay.css";

const ImageOverlay = ({ data }) => {
  const [show, setShow] = useState(false);
  const [imageUrl, setImageUrl] = useState([]);
  useEffect(() => {
    fetch(
      `https://blogspot.colab-makerspace.gr/wp-json/wp/v2/media/${data.acf.image}?_fields=source_url,alt_text`
    )
      .then((response) => response.json())
      .then((fetchedImage) => {
        setImageUrl(fetchedImage.source_url);
      })
      .catch((error) => console.error(error));
  }, [data.acf.image]);
  const showOverlay = () => {
    setShow(true);
  };

  const hideOverlay = () => {
    setShow(false);
  };

  return (
    <a href={data.acf.external_link} target="_blank">
      <div className="container">
        <div
          className="image-container"
          onMouseOver={showOverlay}
          onMouseLeave={hideOverlay}
        >
          <img src={imageUrl} alt="Image" />
          {show && (
            <div className="overlay">
              <h2>{data.title}</h2>
            </div>
          )}
        </div>
      </div>
    </a>
  );
};

export default ImageOverlay;
