import React, { Component } from "react";
import { withTranslation } from "react-i18next"; // Import withTranslation HOC
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Organizations from "../../containers/organizations/Organizations";
import TopButton from "../../components/topButton/TopButton";
import "./Opensource.css";
import "../experience/Experience.css";
import { about } from "../../portfolio.js";
import { Fade } from "react-reveal";
import Slider from "../../containers/slider/Slider";
import { Helmet } from "react-helmet-async";

class Opensource extends Component {
  render() {
    const { t } = this.props; // Access t function from props
    const theme = this.props.theme;

    return (
      <div className="opensource-main">
        <Header theme={this.props.theme} />
        <Helmet>
          <title>{t("opensource_title")} | coLab</title>{" "}
          {/* Use translation key for title */}
          <meta
            name="description"
            content={t("opensource_descriptionMeta")} // Use translation key for description
          />
          <meta
            name="keywords"
            content={t("opensource_keywords")} // Use translation key for keywords
          />
          <link rel="canonical" href="/opensource" />
        </Helmet>
        <div className="basic-experience">
          <Fade bottom duration={2000} distance="40px">
            <div className="experience-heading-div">
              <div className="experience-heading-text-div">
                <h4
                  className="experience-heading-text"
                  style={{ color: theme.text }}
                >
                  {t("opensource_aboutTitle")}{" "}
                  {/* Use translation key for title */}
                </h4>
                <h5
                  className="experience-heading-sub-text"
                  style={{ color: theme.text }}
                >
                  {t("opensource_aboutSubtitle")}{" "}
                  {/* Use translation key for subtitle */}
                </h5>
                <p
                  className="descriptionText"
                  style={{ color: theme.secondaryText }}
                >
                  {t("opensource_aboutDescription")}{" "}
                  {/* Use translation key for description */}
                </p>
                <p
                  className="descriptionText"
                  style={{ color: theme.secondaryText }}
                >
                  {t("opensource_aboutDescription2")}{" "}
                  {/* Use translation key for second description */}
                </p>
                <p className="descriptionText2">
                  {t("opensource_aboutDescription3")}{" "}
                  {/* Use translation key for third description */}
                </p>
              </div>
            </div>
          </Fade>
        </div>
        <div className="empty"></div>
        <Slider />
        {/* <Organizations theme={this.props.theme} /> */}
        <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default withTranslation()(Opensource);
