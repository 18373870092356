import React, { Component } from "react";
import "./Header.css";
import { NavLink, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import SeoHeader from "../seoHeader/SeoHeader";
import Logo from "../../assets/images/colab_rec.png";
// LanguageSwitcher removed from Header.js to avoid duplication

const onMouseEnter = (event, color) => {
  const el = event.target;
  el.style.backgroundColor = color;
};

const onMouseOut = (event) => {
  const el = event.target;
  el.style.backgroundColor = "transparent";
};

class Header extends Component {
  componentDidMount() {
    this.setupDropdownHover();
    this.setupDropdownClick();
  }

  setupDropdownHover() {
    const dropdowns = document.querySelectorAll(".dropdown");

    dropdowns.forEach((dropdown) => {
      dropdown.addEventListener("mouseover", () => {
        if (window.innerWidth > 768) {
          dropdown.querySelector(".dropdown-content").style.display = "block";
        }
      });

      dropdown.addEventListener("mouseout", () => {
        if (window.innerWidth > 768) {
          dropdown.querySelector(".dropdown-content").style.display = "none";
        }
      });
    });
  }

  setupDropdownClick() {
    const dropdowns = document.querySelectorAll(".dropdown");

    dropdowns.forEach((dropdown) => {
      dropdown.addEventListener("click", (event) => {
        if (window.innerWidth <= 768) {
          event.stopPropagation();
          const dropdownContent = dropdown.querySelector(".dropdown-content");
          dropdownContent.style.display =
            dropdownContent.style.display === "block" ? "none" : "block";
        }
      });
    });

    // Close dropdowns if clicked outside
    document.addEventListener("click", (event) => {
      if (window.innerWidth <= 768) {
        dropdowns.forEach((dropdown) => {
          if (!dropdown.contains(event.target)) {
            dropdown.querySelector(".dropdown-content").style.display = "none";
          }
        });
      }
    });
  }

  render() {
    const theme = this.props.theme;
    const { t } = this.props;

    return (
      <div>
        <SeoHeader />
        <header className="header">
          <img src={Logo} alt="logo" className="logo" />
          <input className="menu-btn" type="checkbox" id="menu-btn" />
          <label className="menu-icon" htmlFor="menu-btn">
            <span className="navicon"></span>
          </label>
          <ul className="menu">
            <li>
              <NavLink
                to="/home"
                tag={Link}
                activeStyle={{ fontWeight: "bold" }}
                style={{ color: theme.text_header }}
                onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                onMouseOut={(event) => onMouseOut(event)}
              >
                {t("header_home")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/space"
                tag={Link}
                activeStyle={{ fontWeight: "bold" }}
                style={{ color: theme.text_header }}
                onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                onMouseOut={(event) => onMouseOut(event)}
              >
                {t("header_space")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/programs-adults"
                tag={Link}
                activeStyle={{ fontWeight: "bold" }}
                style={{ color: theme.text_header }}
                onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                onMouseOut={(event) => onMouseOut(event)}
              >
                {t("header_programs_adults")}
              </NavLink>
            </li>
            <li className="dropdown">
              <span
                style={{ color: theme.text_header }}
                onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                onMouseOut={(event) => onMouseOut(event)}
              >
                {t("header_programs_kids")}
              </span>
              <div className="dropdown-content">
                <NavLink
                  to="/robotics_courses"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: theme.text_header }}
                >
                  {t("courses_option1")}
                </NavLink>
                <NavLink
                  to="/game_design_courses"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: theme.text_header }}
                >
                  {t("courses_option2")}
                </NavLink>
                <NavLink
                  to="/programming_courses"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: theme.text_header }}
                >
                  {t("courses_option3")}
                </NavLink>
                <NavLink
                  to="/3dprint_courses"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: theme.text_header }}
                >
                  {t("courses_option4")}
                </NavLink>
              </div>
            </li>
            <li className="dropdown">
              <span
                style={{ color: theme.text_header }}
                onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                onMouseOut={(event) => onMouseOut(event)}
              >
                {t("header_projects")}
              </span>
              <div className="dropdown-content">
                <NavLink
                  to="/3dprint"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: theme.text_header }}
                >
                  {t("projects_option1")}
                </NavLink>
                <NavLink
                  to="/laser"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: theme.text_header }}
                >
                  {t("projects_option2")}
                </NavLink>
                <NavLink
                  to="/booking"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: theme.text_header }}
                >
                  {t("projects_option3")}
                </NavLink>
                <NavLink
                  to="/groups"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: theme.text_header }}
                >
                  {t("projects_option4")}
                </NavLink>
              </div>
            </li>
            <li>
              <NavLink
                to="/pricing"
                tag={Link}
                activeStyle={{ fontWeight: "bold" }}
                style={{ color: theme.text_header }}
                onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                onMouseOut={(event) => onMouseOut(event)}
              >
                {t("header_pricing")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about"
                tag={Link}
                activeStyle={{ fontWeight: "bold" }}
                style={{ color: theme.text_header }}
                onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                onMouseOut={(event) => onMouseOut(event)}
              >
                {t("header_about")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                tag={Link}
                activeStyle={{ fontWeight: "bold" }}
                style={{ color: theme.text_header }}
                onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                onMouseOut={(event) => onMouseOut(event)}
              >
                {t("header_contact")}
              </NavLink>
            </li>
          </ul>
        </header>
      </div>
    );
  }
}

export default withTranslation()(Header);
