import React, { Component } from "react";
import { withTranslation } from "react-i18next"; // Import withTranslation HOC
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
import { Fade } from "react-reveal";
import "./ContactComponent.css";
import GoogleMap from "../../components/map/Map";
import { Helmet } from "react-helmet-async";
import contactImage from "../../assets/images/communication.png"; // Import the image
import blogImage from "../../assets/images/weareopen.png"; // Import the image
import addressImage from "../../assets/images/maps.png"; // Import the image

class Contact extends Component {
  render() {
    const { t } = this.props; // Access t function from props
    const theme = this.props.theme;
    return (
      <div className="contact-main">
        <Header theme={theme} />
        <Helmet>
          <title>{t("contact_title")}</title>
          <meta
            name="description"
            content="Βρείτε μας καθημέρινα στο χώρο μας. Μουρούζη 51, 26223 Πάτρα."
          />
          <meta name="keywords" content="Πάτρα, Study Room" />
          <link rel="canonical" href="/contact" />
        </Helmet>
        <div className="basic-contact">
          <Fade bottom duration={1000} distance="40px">
            <div className="contact-heading-div">
              <div className="contact-heading-img-div">
                <img
                  style={{ width: "55%" }}
                  src={contactImage}
                  alt="Contact"
                />
              </div>
              <div className="contact-heading-text-div">
                <h1
                  className="contact-heading-text"
                  style={{ color: theme.text }}
                >
                  {t("contactSection.title")}
                </h1>
                <p
                  className="contact-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {t("contactSection.description")}
                </p>
                <p
                  className="contact-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {t("contactSection.description2")}
                </p>
                <SocialMedia theme={theme} />
                <div className="resume-btn-div">
                  <Button
                    text={t("contactSection.button")}
                    newTab={true}
                    href={t("contactSection.resumeLink")}
                    theme={theme}
                  />
                </div>
              </div>
            </div>
          </Fade>
          <Fade bottom duration={1000} distance="40px">
            <div className="blog-heading-div">
              <div className="blog-heading-text-div">
                <h1 className="blog-heading-text" style={{ color: theme.text }}>
                  {t("blogSection.title")}
                </h1>
                <p
                  className="blog-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {t("blogSection.subtitle1")}
                </p>
                <p
                  className="blog-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {t("blogSection.subtitle2")}
                </p>
                <p
                  className="blog-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {t("blogSection.subtitle3")}
                </p>
                <p
                  className="blog-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {t("blogSection.subtitle4")}
                </p>
                <p
                  className="blog-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {t("blogSection.subtitle5")}
                </p>
                <p
                  className="blog-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {t("blogSection.subtitle6")}
                </p>
                <p
                  className="blog-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {t("blogSection.subtitle7")}
                </p>
              </div>
              <div className="contact-heading-img-div">
                <img
                  style={{ width: "50%", alignItems: "center" }}
                  src={blogImage}
                  alt="Blog"
                />
              </div>
            </div>
          </Fade>
          <Fade bottom duration={1000} distance="40px">
            <div className="address-heading-div">
              <div className="contact-heading-img-div">
                <img
                  style={{ width: "60%" }}
                  src={addressImage}
                  alt="Address"
                />
              </div>
              <div className="address-heading-text-div">
                <h1
                  className="address-heading-text"
                  style={{ color: theme.text }}
                >
                  {t("addressSection.title")}
                </h1>
                <p
                  className="contact-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {t("addressSection.subtitle")}
                </p>
                <div className="address-btn-div">
                  <GoogleMap />
                </div>
              </div>
            </div>
          </Fade>
        </div>
        <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default withTranslation()(Contact);
