import React, { Component } from "react";
import { withTranslation } from "react-i18next"; // Import withTranslation HOC
import "./Educations02.css";
import DegreeCard from "../../components/degreeCard/DegreeCard.js";
import { Fade } from "react-reveal";

class Educations02 extends Component {
  render() {
    const { t } = this.props; // Access t function from props
    const theme = this.props.theme;

    // Extract degrees from translation
    const degrees = t("degrees02", { returnObjects: true });

    // Log degrees to see its structure
    console.log("degrees:", degrees);

    // Check if degrees is an array
    if (!Array.isArray(degrees)) {
      return <div>Error: degrees is not an array</div>;
    }

    return (
      <div className="main" id="educations">
        <div className="educations-body-div">
          {degrees.map((degree, index) => (
            <DegreeCard key={index} degree={degree} theme={theme} />
          ))}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Educations02);
