/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "coLab Makerspace",
  description: "The Patras' Makerspace",
  og: {
    title: "coLab Makerspace",
    type: "website",
    url: "http://colab-makerspace.gr",
  },
};

//Home Page
const greeting = {
  title: "coLab Makerspace",
  logo_name: "coLab Makerspace",
  nickname: "coLab",
  subTitle: "coLab Tagline",
  resumeLink:
    "https://drive.google.com/file/d/1FL_wydnPF2SWCdw2goYAgZ1KKBx8TpcD/view?usp=sharing",
  contactLink: "https://forms.gle/nKxBepPNaKPUgwbh7",
  portfolio_repository: "https://github.com/ashutosh1919/masterPortfolio",
  githubProfile: "https://github.com/ashutosh1919",
  developer: "insidethebox.dev",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Gmail",
    link: "mailto:colab.patras@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  // {
  //   name: "Twitter",
  //   link: "https://twitter.com/ashutosh_1919",
  //   fontAwesomeIcon: "fa-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
  //   backgroundColor: "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
  // },
  {
    name: "Facebook",
    link: "https://www.facebook.com/coLabMakerspace",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/colab.makerspace/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/company/colab-makerspace",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "YouTube",
    link: "https://youtube.com/@coLabMakerspace",
    fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
    backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  },
  // {
  //   name: "TikTok",
  //   link: "https://www.tiktok.com/@colab_makerspace",
  //   fontAwesomeIcon: "fa-tiktok", // Reference https://fontawesome.com/icons/github?style=brands
  //   backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  // }
];

const skills = {
  data: [
    {
      title: "Η τεχνολογία στα χέρια σου",
      fileName: "FullStackImg",
      skills: [
        "⚛ Το coLab διαθέτει ένα σύγχρονο Open Lab, εξοπλισμένο με ένα ευρύ φάσμα εργαλείων και εξοπλισμού, ιδανικό για τις ανάγκες του κάθε maker.",
        "⚛ Κάνε τη συνδρομή σου και απόκτησε ελέυθερη πρόσβαση σε πόρους αιχμής, ώστε να κάνεις την κάθε ιδεα σου πραγματικότητα.",
        "⚛ Πειραματίσου με κυκλώματα, αισθητήρες, κινητήρες και πολλά άλλα σε ένα συνεργατικό και δημιουργικό περιβάλλον.",
      ],
      link: "/#/space",
      image_path: "technology.png",
    },
    {
      title: "Απελευθέρωσε τη δημιουργικότητά σου με εργαλεία αιχμής",
      fileName: "CreativityImg",
      skills: [
        "⚛ Με τις δυνατότητες ενός σύγχρονου 3D Printer και ενός Laser Engraver καμία ιδέα δεν είναι πια ανέφικτη.",
        "⚛ Ζωντάνεψε τα σχέδια σου και ανέπτυξε τα έργα σου με ακρίβεια και λεπτομέρεια.",
        "⚛ Ως μέλος, απόλαυσε ειδικές εκπτώσεις και προνόμια στη χρήση του 3D Printer και του Laser Engraver.",
      ],
      link: "/#/space",
      image_path: "creativity.png",
    },
    {
      title: "Ευέλικτοι χώροι ιδανικοί για κάθε ανάγκη",
      fileName: "WorkspaceImg",
      skills: [
        "⚛ Διάβασε και εργάσου σε ένα χώρο ειδικά σχεδιασμένο για τις ανάγκες σου.",
        "⚛ Άλλαξε χώρο και γίνε πιο παραγωγικός δουλεύοντας σε ένα πιο ήρεμο και βολικό περιβάλλον.",
        "⚛ Coworking Room ιδανικό για συναντησεις ομάδων και για ομαδικές δραστηρίοτητες.",
        "⚛ Διοργάνωσε ένα event και κάνε χρήση των σύγχρονων υποδομών του coLab.",
      ],
      link: "/#/space",
      image_path: "workspace.png",
    },
    {
      title: "Γίνε expert στις νέες τεχνολογίες.",
      fileName: "TechnologyImg",
      skills: [
        "⚛ Τμήματα Προγραμματισμού, Ρομποτικής & Αυτοματισμών και 3D Design & Printing.",
        "⚛ Ειδικά διαβαθμισμένα τμήματα για αρχάριους και πιο έμπειρους.",
        "⚛ Προετοιμασία και συμμετοχή σε διαγωνισμούς Ρομποτικής και Προγραμματισμού.",
        "⚛ Σεμινάρια μικρής διάρκειας και workshops σε τεχνολογίες αιχμής.",
        "⚛ Έναρξη τμημάτων καθόλη τη διάρκεια του έτους.",
      ],
      link: "/#/programs-adults",
      image_path: "collaboration.png",
    },
    {
      title:
        "Προετοιμάζοντας τις επόμενες γενιές για την κοινωνία του μέλλοντος.",
      fileName: "TeachingImg",
      skills: [
        "⚛ Κύκλοι μαθημάτων για ηλικίες από 6 έως 18 ετών, διαβαθμισμένοι ανάλογα με τις γνώσεις και την ηλικία του κάθε μαθητή.",
        "⚛ Εξερευνήστε τον μαγικό κόσμο της Ρομποτικής μέσω του Lego Spike και γίνετε ειδικοί στη χρήση πλατφορμών ανοιχτου λογισμικού, όπως Microbit, Arduino, Raspberry Pi και πολλών άλλων..",
        "⚛ Ειδική προετοιμασία για τη συμμετοχή σε διαγωνισμούς εκπαιδευτικής ρομποτικής σε εθνικό και διεθνές επίπεδο.",
        "⚛ Εξερευνήστε την τέχνη του προγραμματισμού και δημιουργήστε τα δικά σας προγράμματα μέσω της Python.",
        "⚛ Δημιουργήστε 3D Αντικείμενα και χαρακτήρες και δώστε τους ζωή μέσω του κύκλου 3D Design & Printing.",
      ],
      link: "/#/programs-kids",
      image_path: "teaching.png",
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://leetcode.com/layman_brother/",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/layman_brother",
    },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
      profileLink: "https://www.codechef.com/users/ashutosh_1919",
    },
    {
      siteName: "Codeforces",
      iconifyClassname: "simple-icons:codeforces",
      style: {
        color: "#1F8ACB",
      },
      profileLink: "http://codeforces.com/profile/layman_brother",
    },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      profileLink: "https://www.hackerearth.com/@ashutosh391",
    },
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/laymanbrother",
    },
  ],
};

const degrees = {
  degrees: [
    // {
    //   title: "coLab Summer Camp",
    //   subtitle: "Ηλικίες 8-12",
    //   logo_path: "summer_camp.png",
    //   alt_name: "coLab Summer Camp",
    //   series: "🌊 Summer Courses",
    //   body: "card-body7",
    //   img: "card-img7",
    //   descriptions: [
    //     "☀️ 4 θεματικές εβδομάδες, 3 ημέρες την εβδομάδα από 3 ώρες κάθε μέρα (Δευ., Τετ. & Παρ. 11:00 - 14:00)",
    //     "☀️ 1η Εβδομάδα 25/06 - 28/06 | Προστατεύοντας το Περιβάλλον",
    //     "☀️ 2η Εβδομάδα 01/07 - 05/07 | Εξερευνώντας το Διάστημα",
    //     "☀️ 3η Εβδομάδα 08/07 - 12/07 | Συμμετέχοντας στους Ολυμπιακούς Αγώνες",
    //     "☀️ 4η Εβδομάδα 15/07 - 19/06 | Ανακαλύπτωντας τα μυστήρια της θάλασσας",
    //     "☀️ Ένα καλοκαίρι γεμάτο από Ρομποτική, Προγραμματισμό, Κατασκευές και Ομαδικές Δραστηριότητες!",
    //   ],
    //   website_link: "https://forms.gle/3yrfEXWP3aNJXdvC7",
    // },
    // {
    //   title: "Προγραμματισμός Drones",
    //   subtitle: "Ηλικίες 12-16",
    //   logo_path: "drone.png",
    //   alt_name: "Προγραμματισμός Drones",
    //   series: "🌊 Summer Courses",
    //   body: "card-body7",
    //   img: "card-img7",
    //   descriptions: [
    //     "☀️ Διάρκεια: 1 εβδομάδα",
    //     "☀️ 3 ημέρες την εβδομάδα από 2 ώρες κάθε μέρα (Δευ., Τετ. & Παρ. 17:00 - 19:00)",
    //     "☀️ 1ο Group 25 Ιουνίου  με 28 Ιουνίου",
    //     "☀️ 2ο Group - 2 με 11 Ιουλίου",
    //     "☀️ 3ο Group - 7 με 11 Ιουλίου",
    //     "☀️ 4ο Group - 14 με 18 Ιουλίου",
    //     "☀️ Μάθε τα πάντα για τα drones.",
    //     "☀️ Προγραμμάτισε τα δικά σου drones και κάνε τα να πετάξουν.",
    //     "☀️ Λύσε δύσκολες αποστολές  προγραμματίζοντας κατάλληλα την κίνησή τους.",
    //   ],
    //   website_link: "https://forms.gle/yYA31eFXyVLKUaw8A",
    // },
    {
      title: "Spike Junior",
      subtitle: "Ηλικίες 6-9",
      logo_path: "spike_es.png",
      alt_name: "Spike Junior",
      series: "Junior Robotics",
      body: "card-body1",
      img: "card-img1",
      descriptions: [
        "🎯 Ο καλύτερος τρόπος για να εισαχθεί ένα παιδί στον κόσμο της ρομποτικής.",
        "🎯 Αξιοποιώντας την εκπαιδευτική πλατφόρμα Lego Spike: Essential τα παιδιά μαθαίνουν παίζοντας.",
        "🎯 Κάθε μάθημα σχεδιάζεται γύρω από ένα θέμα της καθημερινότητας. Έτσι άλλοτε καλούμαστε να γίνουμε εξερευνητές της φύσης, άλλοτε οδηγοί αγώνων και άλλοτε ατρόμητοι αστροναύτες.",
        "🎯 Μέσα από συνεργατικές διαδικασίες τα παιδιά φαντάζονται και δημιουργούν ρομποτικές λύσεις τόσο για τα προβλήματα του σήμερα όσο και για εκείνα του μέλλοντος.",
        "🎯 Προσιτή εισαγωγή στη γλώσσα προγραμματισμού Scratch.",
      ],
      website_link: "https://forms.gle/YyfFth55uYic7jjw9",
    },
    {
      title: "Spike Junior+",
      subtitle: "Ηλικίες 7-10",
      logo_path: "spike_pr.png",
      alt_name: "Spike Junior+",
      series: "Junior Robotics",
      body: "card-body1",
      img: "card-img1",
      descriptions: [
        "🎯 Συνεχίζοντας τον κύκλο του Spike Junior, ξεκινάμε να πειραματιζόμαστε με πιο σύνθετες κατασκευές και πιο περίπλοκους κώδικες.",
        "🎯 Με τη χρήση των πλατφορμών Lego Spike Prime και Essential, οι μαθητές ξεκινούν να δημιουργούν τις δικές τους ρομποτικές λύσεις.",
        "🎯 Χρησιμοποιώντας τη γλώσσα προγραμματισμού Scratch τα παιδιά μαθαίνουν να επικοινωνούν κάθε ιστορία με ακόμα περισσότερους διαδραστικούς τρόπους.",
      ],
      website_link: "https://forms.gle/LcaCXkX2t8oDVq3r6",
    },
    {
      title: "Junior Prime",
      subtitle: "Ηλικίες 9-12",
      logo_path: "spike_pr2.png",
      alt_name: "Junior Prime",
      series: "Senior Robotics",
      body: "card-body2",
      img: "card-img2",
      descriptions: [
        "🎯 Γνωρίστε τη σύγχρονη πλατφόρμα του Lego Spike Prime, ιδανική τόσο για αρχάριους, όσο και για πιο έμπειρους μαθητές.",
        "🎯 Προετοιμαστείτε κατάλληλα για το ταξίδι σας στο μαγικό κόσμο της εκπαιδευτικής ρομποτικής.",
        "🎯 Κατασκευάστε και προγραμματίστε μοναδικά ρομπότ και ετοιμαστείτε για πολλές νέες περιπέτειες.",
        "🎯 Ανακαλύψτε τη σημασία της συνεργασίας μέσα από ομαδικές δραστηριότητες και παιχνίδια.",
      ],
      website_link: "https://forms.gle/BahEsiGAYxjyeuW8A",
    },
    {
      title: "Junior Prime+",
      subtitle: "Ηλικίες 10-14",
      logo_path: "spike_pr3.png",
      alt_name: "Junior Prime+",
      series: "Senior Robotics",
      body: "card-body2",
      img: "card-img2",
      descriptions: [
        "🎯 Μάθε πως να εφαρμόζεις σύγχρονους αλγορίθμους Ρομποτικής στο Lego Spike.",
        "🎯 Έλεγξε τα όρια του Lego Spike Prime και μάθε τρόπους για να τα ξεπερνάς.",
        "🎯 Προετοιμάσου για διαγωνισμούς ρομποτικής και ζήσε από κοντά την εμπειρία μίας μεγάλης διοργάνωσης.",
      ],
      website_link: "https://forms.gle/x6n65BKHHkdd2ner5",
    },
    {
      title: "Microbit Experts",
      subtitle: "Ηλικίες 11-14",
      logo_path: "microbit.png",
      alt_name: "Microbit Experts",
      series: "Senior Robotics",
      body: "card-body2",
      img: "card-img2",
      descriptions: [
        "🎯 Κάνε τα πρώτα βήματα σου στον κόσμο του Making με τη πρωτοποριακή πλακέτα Microbit, που συνδυάζει την ηλεκτρονική και τις ανοιχτές τεχνολογίες με την προσιτότητα του Scratch.",
        "🎯 Μάθε να χωρίζεις το οποιοδήποτε πρόβλημα σε άλλα μικρότερα και να δίνεις λύση μέσω της ρομποτικής.",
        "🎯 Γνώρισε τους βασικούς τύπους αισθητήρων και κινητήρων και μάθε να επιλέγεις τους κατάλληλους για το κάθε project.",
        "🎯 Φτιάξε απίθανες κατασκευές και δώσε τους ζωή εύκολα και γρήγορα μέσω ενός Microbit.",
      ],
      website_link: "https://forms.gle/ZVxmD1VHj9Xa3GG8A",
    },
    {
      title: "Arduino Experts",
      subtitle: "Ηλικίες 12-18",
      logo_path: "arduino.png",
      alt_name: "Arduino Experts",
      series: "Advanced Robotics",
      body: "card-body3",
      img: "card-img3",
      descriptions: [
        "🎯 Γνώρισε τη πλακέτα ανοιχτού κώδικα που χρησιμοποιούν εκατομμύρια Makers σε όλο τον κόσμο.",
        "🎯 Μάθε πως λειτουργούν οι κάθε είδους αισθητήρες και κινητήρες και χρησιμοποίησε τους για τα πιο απίθανα projects.",
        "🎯 Σχεδίασε και προγραμμάτισε τα δικά σου projects και δώσε λύση σε προβλήματα της καθημερινότητας.",
      ],
      website_link: "https://forms.gle/taKJPjk88RAyXnZb8",
    },
    {
      title: "IoT Experts",
      subtitle: "Ηλικίες 14-18",
      logo_path: "raspberry2.png",
      alt_name: "Raspberry Pi Experts",
      series: "Advanced Robotics",
      body: "card-body3",
      img: "card-img3",
      descriptions: [
        "🎯 Γνώρισε τις πλατφόρμες ανοικτού λογισμικού που χρησιμοποιούνται ευρέως στο Διαδίκτυο των Πραγμάτων.",
        "🎯 Μάθε να σχεδιάζεις και να προγραμματίζεις αυτοματισμούς και εφαρμογές και δώσε λύση στα καθημερινά προβλήματα με λύσεις αιχμής.",
        "🎯 Εξοπλίσου με τις απαραίτητες δεξιότητες για το μέλλον και γίνε ο expert του Internet of Things.",
      ],
      website_link: "https://forms.gle/LXoLqtPb4kLzMgo68",
    },
    // {
    //   title: "Αγωνιστική Ομάδα: FLL Challenge",
    //   subtitle: "Ηλικίες 12-16",
    //   logo_path: "fll.png",
    //   alt_name: "Αγωνιστική Ομάδα: FLL Challenge",
    //   series: "Competitive Robotics ",
    //   body: "card-body4",
    //   img: "card-img4",
    //   descriptions: [
    //     "🎯 Δώσε απάντηση στο φετινό πρόβλημα του διαγωνισμού, συνδυάζοντας τη Ρομποτική με τη Τέχνη.",
    //     "🎯 Ανέπτυξε την ομαδικότητα σου, συμμετέχοντας σε μία μεγάλη ομάδα για την ανάπτυξη ενός σύνθετου project.",
    //     "🎯 Προγραμμάτισε πλακέτες ανοιχτού λογισμικού (Arduino, Raspberry Pi) και δημιούργησε το πιο μοναδικό Innovation Project.",
    //     "🎯 Κατασκεύασε και προγραμμάτισε το Lego Spike ρομπότ σου και μάζεψε όσο περισσότερους πόντους μπορείς στο Robot Game.",
    //     "🎯 Συνομίλησε με άτομα που δραστηριοποιούνται και διαπρέπουν στον τομέα των τεχνών και των νέων τεχνολογιών μέσω εκπαιδευτικών εκδρομών και online συναντήσεων.",
    //   ],
    //   website_link: "https://forms.gle/R8CrcPuvx5m9KACG6",
    // },
    {
      title: "Python Programming",
      subtitle: "Ηλικίες 12-18",
      logo_path: "python.png",
      alt_name: "Python Programming",
      series: "Programming",
      body: "card-body5",
      img: "card-img5",
      descriptions: [
        "🎯 Γνώρισε μία από τις πιο δημοφιλείς γλώσσες προγραμματισμού",
        "🎯 Βήμα βήμα μάθε να προγραμματίζεις από το πιο απλό έως το πιο σύνθετο πρόγραμμα.",
        "🎯 Μέσα από ευχάριστες δραστηριότητες και προκλήσεις, γίνε expert στον προγραμματισμό.",
        "🎯 Απόκτησε μια σημαντική δεξιότητα του μέλλοντος γνωρίζοντας μια γλώσσα που χρησιμοποιέιται σε περισσότερους τομείς από οποιαδήποτε άλλη.",
      ],
      website_link: "https://forms.gle/LLGMf4GN5r9TiiE4A",
    },
    {
      title: "Junior 3D Design & 3D Printing",
      subtitle: "Ηλικίες 9-12",
      logo_path: "3d1.png",
      alt_name: "Junior 3D Design & 3D Printing",
      series: "DIY & Making",
      body: "card-body6",
      img: "card-img6",
      descriptions: [
        "🎯 Εισαγωγή στον κόσμο της 3D Σχεδίασης και του 3D Printing με τη χρήση του Tinkercad. Ενός εργαλείου ειδικά σχεδιασμένου για να κάνει προσιτή τη 3D σχεδίαση στις μικρές ηλικίες",
        "🎯 Μάθε πως να σχεδιάζεις από τα πιο απλά μέχρι τα πιο σύνθετα αντικείμενα και δώσε λύση σε προβλήματα της καθημερινότητας.",
        "🎯 Μάθε πως λειτουργούν οι 3D εκτυπωτές και μάθε να εκτυπώνεις τα δικά σου σχέδια.",
      ],
      website_link: "https://forms.gle/XSHY3jwaxpJkeLLX6",
    },
    {
      title: "3D Design & 3D Printing",
      subtitle: "Ηλικίες 12-18",
      logo_path: "3d3.png",
      alt_name: "3D Design & 3D Printing",
      series: "DIY & Making",
      body: "card-body6",
      img: "card-img6",
      descriptions: [
        "🎯 Μάθε να δημιουργείς με επαγγελματικά λογισμικά 3D Σχεδίασης.",
        "🎯 Δημιούργησε τους δικούς σου χαρακτήρες.",
        "🎯 Απόκτησε ευχέρεια στη 3D Σχεδίαση και δημιούργησε τα δικά σου ρομπότ από το μηδέν.",
        "🎯 Απόκτησε μια από τις σημαντικές δεξίοτητες του μέλλοντος.",
      ],
      website_link: "https://forms.gle/Sr21vTLgLt6iybxT6",
    },
  ],
};

const degrees2 = {
  degrees2: [
    {
      title: "Open Electronics: Arduino",
      subtitle: "",
      logo_path: "arduino.png",
      alt_name: "Open Electronics: Arduino",
      series: "Open Electronics",
      body: "card-body5",
      img: "card-img5",
      descriptions: [
        "🎯 Γνώρισε πλακέτες ανοιχτού κώδικα οι οποίες χρησιμοποιούνται από εκατομμύρια Makers σε όλο τον κόσμο.",
        "🎯 Μάθε πως λειτουργούν οι κάθε είδους αισθητήρες και κινητήρες και χρησιμοποίησε τους για τα δικά σε hands-on projects.",
        "🎯 Σχεδίασε και προγραμμάτισε τα δικά σου projects και δώσε λύση σε προβλήματα της καθημερινότητας.",
      ],
      website_link: "https://forms.gle/p5o6ZNmpbfrS7SS68",
    },
    {
      title: "Open Electronics: IoT",
      subtitle: "",
      logo_path: "raspberry2.png",
      alt_name: "Open Electronics: IoT",
      series: "Open Electronics",
      body: "card-body5",
      img: "card-img5",
      descriptions: [
        "🎯 Γνώρισε τις πλατφόρμες ανοικτού λογισμικού που χρησιμοποιούνται ευρέως στο Διαδίκτυο των Πραγμάτων.",
        "🎯 Μάθε να σχεδιάζεις και να προγραμματίζεις αυτοματισμούς και εφαρμογές και εφάρμοσε τις γνώσεις σου σε πραγματικά projects.",
        "🎯 Σχεδίασε τους δικού σου αυτοματισμούς με Raspberry Pi και άλλες πλακέτες και αυτοματοποίησε την καθημερινότητα σου.",
      ],
      website_link: "https://forms.gle/AQPLhsy1CExfzAJv9",
    },
    {
      title: "Python Programming",
      subtitle: "",
      logo_path: "python.png",
      alt_name: "Python Programming",
      series: "Programming",
      body: "card-body5",
      img: "card-img5",
      descriptions: [
        "🎯 Γνώρισε μία από τις πιο δημοφιλείς γλώσσες προγραμματισμού",
        "🎯 Βήμα βήμα μάθε να προγραμματίζεις από το πιο απλό έως το πιο σύνθετο πρόγραμμα.",
        "🎯 Μέσα από ευχάριστες δραστηριότητες και προκλήσεις, γίνε expert στον προγραμματισμό.",
        "🎯 Απόκτησε μια σημαντική δεξιότητα του μέλλοντος γνωρίζοντας μια γλώσσα που χρησιμοποιέιται σε περισσότερους τομείς από οποιαδήποτε άλλη.",
      ],
      website_link: "https://forms.gle/nF17vyapzGTp2oEZA",
    },
    {
      title: "3D Design & 3D Printing",
      subtitle: "",
      logo_path: "3d3.png",
      alt_name: "3D Design & 3D Printing",
      series: "DIY & Making",
      body: "card-body5",
      img: "card-img5",
      descriptions: [
        "🎯 Μάθε να δημιουργείς με επαγγελματικά λογισμικά 3D Σχεδίασης.",
        "🎯 Δημιούργησε τους δικούς σου χαρακτήρες.",
        "🎯 Απόκτησε ευχέρεια στη 3D Σχεδίαση και δημιούργησε τα δικά σου ρομπότ από το μηδέν.",
        "🎯 Απόκτησε μια από τις σημαντικές δεξίοτητες του μέλλοντος.",
      ],
      website_link: "https://forms.gle/rhJ2saMwMiMCVyQF7",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Machine Learning",
      subtitle: "- Andrew Ng",
      logo_path: "stanford_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/22MTSSC5WDTM",
      alt_name: "Stanford University",
      color_code: "#8C151599",
    },
    {
      title: "Deep Learning",
      subtitle: "- Andrew Ng",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/H8CPSFXAJD2G",
      alt_name: "deeplearning.ai",
      color_code: "#00000099",
    },
    {
      title: "ML on GCP",
      subtitle: "- GCP Training",
      logo_path: "google_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/EB4VJARK8647",
      alt_name: "Google",
      color_code: "#0C9D5899",
    },
    {
      title: "Data Science",
      subtitle: "- Alex Aklson",
      logo_path: "ibm_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/PLEAPCSJBZT5",
      alt_name: "IBM",
      color_code: "#1F70C199",
    },
    {
      title: "Big Data",
      subtitle: "- Kim Akers",
      logo_path: "microsoft_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/164zKCFOsI4vGqokc-Qj-e_D00kLDHIrG/view",
      alt_name: "Microsoft",
      color_code: "#D83B0199",
    },
    {
      title: "Advanced Data Science",
      subtitle: "- Romeo Kienzler",
      logo_path: "ibm_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/BH2T9BRU87BH",
      alt_name: "IBM",
      color_code: "#1F70C199",
    },
    {
      title: "Advanced ML on GCP",
      subtitle: "- GCP Training",
      logo_path: "google_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/5JZZM7TNQ2AV",
      alt_name: "Google",
      color_code: "#0C9D5899",
    },
    {
      title: "DL on Tensorflow",
      subtitle: "- Laurence Moroney",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/6T4DCUGNK8J8",
      alt_name: "deeplearning.ai",
      color_code: "#00000099",
    },
    {
      title: "Fullstack Development",
      subtitle: "- Jogesh Muppala",
      logo_path: "coursera_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/certificate/NRANJA66Y2YA",
      alt_name: "Coursera",
      color_code: "#2A73CC",
    },
    {
      title: "Kuberenetes on GCP",
      subtitle: "- Qwiklabs",
      logo_path: "gcp_logo.png",
      certificate_link:
        "https://google.qwiklabs.com/public_profiles/e4d5a92b-faf6-4679-a70b-a9047c0cd750",
      alt_name: "GCP",
      color_code: "#4285F499",
    },
    {
      title: "Cryptography",
      subtitle: "- Saurabh Mukhopadhyay",
      logo_path: "nptel_logo.png",
      certificate_link:
        "https://drive.google.com/open?id=1z5ExD_QJVdU0slLkp8CBqSF3-C3g-ro_",
      alt_name: "NPTEL",
      color_code: "#FFBB0099",
    },
    {
      title: "Cloud Architecture",
      subtitle: "- Qwiklabs",
      logo_path: "gcp_logo.png",
      certificate_link:
        "https://google.qwiklabs.com/public_profiles/5fab4b2d-be6f-408c-8dcb-6d3b58ecb4a2",
      alt_name: "GCP",
      color_code: "#4285F499",
    },
  ],
};

// Σχετικά με εμάς Page

const about = {
  title: "Λίγα λόγια για εμάς",
  subtitle: "Η τεχνολογία στα χέρια όλων.",
  description:
    "Το coLab δημιουργήθηκε το 2023 με στόχο τη διάχυση των γνώσεων σχετικά με τις νέες τεχνολογίες καθώς και την παροχή των εργαλείων που χρειάζονται αυτές για να αναπτυχθούν.\
    Αποτελεί μία ιδέα που γεννήθηκε στις αίθουσες και τα εργαστήρια του Πανεπιστημίου Πατρών μέσα από την ανάγκη ύπαρξης ενός χώρου στον οποίο η επιστήμη και η τεχνολογία θα\
    αποκτούν εφαρμογή και θα γίνονται προσιτές προς όλους.",
  description2:
    "Έτσι, με έναν κοινό στόχο, αν και προερχόμενοι από διαφορετικούς χώρους, όπως αυτός της Ρομποτικής, του Προγραμματισμού, της Χημείας\
    του STEAM και της Εκπαίδευσης, αποφασίσαμε να δημιουργήσουμε το coLab. Έναν πολυχώρο στον οποίο μπορούν να συνυπάρχουν τα μαθήματα STEAM για παιδιά\
    με τα σεμινάρια και τα εκπαιδευτικά προγράμματα στις νέες τεχνολογίες για ενήλικες. Έναν χώρο αρκετά ήσυχο, ιδανικό για διάβασμα και παράλληλα έναν χώρο ζωντανό με εξοπλισμό\
    που καλύπτει την κάθε ανάγκη ενός DIY project. Έναν χώρο ανοιχτό, στον οποίο ο καθένας θα βρίσκει τα απαραίτητα εφόδια \
    για να κάνει πραγματικότητα την όποια δημιουργική ιδέα του. Έναν χώρο που θα συγκεντρώσει και θα μεγαλώσει την κοινότητα\
    των makers της περιοχής της Πάτρας.",
  description3: "Έναν χώρο που σκεφτόμαστε, συνεργαζόμαστε και δημιουργούμε!",
  header_image_path: "experience.svg",
};

// Experience Page

const programsAdults = {
  title: "Προγράμματα για Ενήλικες",
  subtitle: "Think. Create. Collaborate.",
  description: "Σεμινάρια & Workshops",
};

const programsKids = {
  title: "Προγράμματα για Παιδιά",
  subtitle: "Fun. Creativity. Learning.",
  description: "Εκπαιδευτικά προγράμματα για ηλικίες 6 έως 18.",
  link: "/#/contact",
  buttonText: "Τα καλοκαιρινά προγράμματα του coLab μόλις έφτασαν!",
  //"Δεν είστε σίγουροι, ποιο πρόγραμμα να επιλέξετε, ή θέλετε να μάθετε περισσότερες πληροφορίες για τα προγράμματα; Επικοινωνήστε μαζί μας για να σας λύσουμε κάθε απορία!",
  buttonText2:
    "Για περισσότερες πληροφορίες για τα καλοκαιρινά προγράμματα αλλά και για τα τμήματα που θα ξεκινήσουν από Σεπτέμβρη, επικοινωνήστε μαζί μας.",
};

const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I have worked with many evolving startups as ML and DL Developer, Designer and Software Architect. I have also worked with some well established companies mostly as AI Developer. I love organising events and that is why I am also involved with many opensource communities as a representative.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Associate AI Engineer",
          company: "Legato Health Technology",
          company_url: "https://legatohealthtech.com/",
          logo_path: "legato_logo.png",
          duration: "June 2020 - Aug 2021",
          location: "Hyderabad, Telangana",
          description:
            "I am working on automating healthcare products. The projects involve automation for process improvements and for significantly enhancing the profits. I am currently working on Cancer Survival and Reoccurence Prediction. Our goal is to make AI system which scales and removes doctor dependency as much as possible.",
          color: "#0879bf",
        },
        {
          title: "Android and ML Developer",
          company: "Muffito Incorporation",
          company_url: "https://www.linkedin.com/company/muffito-inc/about/",
          logo_path: "muffito_logo.png",
          duration: "May 2018 - Oct 2018",
          location: "Pune, Maharashtra",
          description:
            "I have created complete Android Application for locating Pub, Bar and beverage shops around you. I have also worked on implementation of algorithms for Face Detection, Text extraction from Image. I was involved in a team for creating complete software architecure of mobile and web application as well as admin panel for company.",
          color: "#9b1578",
        },
        {
          title: "Android Developer",
          company: "FreeCopy Pvt. Ltd.",
          company_url: "https://www.linkedin.com/company/freecopy/about/",
          logo_path: "freecopy_logo.png",
          duration: "Nov 2017 - Dec 2017",
          location: "Ahmedabad, Gujarat",
          description:
            "FreeCopy is the Start up from Indian Institute of Management, Ahmedabad. I have changed the integration of the whole app from Google to Firebase. I learnt the efﬁcient ways of Data communications like Retroﬁt, Eventbus etc. I experienced the real time start up. I learnt the Design thinking of UI on perspective of People.",
          color: "#fc1f20",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Machine Learning Intern",
          company: "TikTok Inc.",
          company_url: "https://www.tiktok.com/en/",
          logo_path: "tiktok_logo.png",
          duration: "May 2022 - Aug 2022",
          location: "San Francisco, USA",
          description:
            "Building new features on the backend recommendation system, specifically ranking algorithms for Ads that touch hundreds of millions of people around the world. Improving online and offline content ranking algorithms by performing hard sample data replays for training steps.",
          color: "#000000",
        },
        {
          title: "Data Science Research Intern",
          company: "Delhivery Pvt. Ltd.",
          company_url: "https://www.delhivery.com/",
          logo_path: "delhivery_logo.png",
          duration: "May 2019 - Sept 2019",
          location: "Gurgaon, Haryana",
          description:
            "I have worked on project of predicting freight rates based on previous data. There were two objectives: (1) To build a forecasting engine to predict daily freight rates. (2) To embed feature in the model which can explain the seasonal major changes in freight rate based on regions and locations. I have closely worked with deep learning models in combination with statistical methods to create solution for this. At the end of internship, I had created model deployed on AWS EC2 with the use of Kafka stream jobs, ElasticSearch and PostgreSQL.",
          color: "#ee3c26",
        },
        {
          title: "Data Science Intern",
          company: "Intel Indexer LLC",
          company_url:
            "https://opencorporates.com/companies/us_dc/EXTUID_4170286",
          logo_path: "intel_logo.jpg",
          duration: "Nov 2018 - Dec 2018",
          location: "Work From Home",
          description:
            "This is financial Solution Company. I have made Supervised Learning model for the company which can perform time series analysis on Stock price data for 32 companies. I have built LSTM Neural Networks Model and trained the data of 32 companies for last 2 years. This model is also used for forecasting.",
          color: "#0071C5",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Google Explore ML Facilitator",
          company: "Google",
          company_url: "https://about.google/",
          logo_path: "google_logo.png",
          duration: "June 2019 - April 2020",
          location: "Hyderabad, Telangana",
          description:
            "Explore Machine Learning (ML) is a Google-sponsored program for university students to get started with Machine Learning. The curriculum offers 3 tracks of ML Content (Beginner, Intermediate, Advanced) and relies on university student facilitators to train other students on campus and to build opensource projects under this program.",
          color: "#4285F4",
        },
        {
          title: "Microsoft Student Partner",
          company: "Microsoft",
          company_url: "https://www.microsoft.com/",
          logo_path: "microsoft_logo.png",
          duration: "Aug 2019 - May 2020",
          location: "Hyderabad, Telangana",
          description:
            "Microsoft Student Partner is a program for university students to lead the awareness and use of Cloud especially Azure tools in the development of their projects and startups. Under this program, I have organised hands on workshops and seminars to teach Cloud Computing concepts to students.",
          color: "#D83B01",
        },
        {
          title: "Mozilla Campus Captain",
          company: "Mozilla",
          company_url: "https://www.mozilla.org/",
          logo_path: "mozilla_logo.png",
          duration: "Oct 2019 - May 2020",
          location: "Kurnool, Andhra Pradesh",
          description:
            "My responsibility for this program was to create opensource environment in college and in the city. We have organised multiple hackathons on the problems collected by ordinary people from Kurnool city. We have build opensource community of our own college. The community is available at dsc_iiitdmk on github.",
          color: "#000000",
        },
        {
          title: "Developer Students Club Member",
          company: "DSC IIITDM Kurnool",
          company_url:
            "https://www.linkedin.com/company/developer-students-club-iiitdm-kurnool",
          logo_path: "dsc_logo.png",
          duration: "Jan 2018 - May 2020",
          location: "Kurnool, Andhra Pradesh",
          description:
            "We have well established developer club in college which is directly associated with Google Developers. We have developed many interdisciplinary projects under the membership of this club. We have organised workshops and activities on Android Application Development, Flutter and React JS.",
          color: "#0C9D58",
        },
        {
          title: "Developer Program Member",
          company: "Github",
          company_url: "https://github.com/",
          logo_path: "github_logo.png",
          duration: "July 2019 - PRESENT",
          location: "Work From Home",
          description:
            "I am actively contributing to many opensource projects. I have contributed to projects of organisations like Tensorflow, Uber, Facebook, Google, Scikit-learn, Kiwix, Sympy, Python, NVLabs, Fossasia, Netrack, Keras etc. These contributions include bug fixes, feature requests and formulating proper documentation for project.",
          color: "#181717",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Data Science projects and deploy them to web applications using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "I have worked on and published a few research papers and publications of my own.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzQ=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzi=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNze=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzt=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzb=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Τρόποι Επικοινωνίας",
    profile_image_path: "communication.png",
    description: "Τηλ. Επικοινωνίας: 261 181 9600",
    description2: "Email: colab.patras@gmail.com",
    resumeLink: "https://forms.gle/nKxBepPNaKPUgwbh7",
  },
  blogSection: {
    title: "Ωράριο Λειτουργίας",
    subtitle1: "Δευτέρα; 10:00 - 21:00",
    subtitle2: "Τρίτη: 10:00 - 21:00",
    subtitle3: "Τετάρτη: 10:00 - 21:00",
    subtitle4: "Πέμπτη: 10:00 - 21:00",
    subtitle5: "Παρασκευή: 10:00 - 21:00",
    subtitle6: "Σάββατο: 10:00 - 21:00",
    subtitle7: "Κυριακή: Kλειστά",

    link: "https://blogs.ashutoshhathidara.com/",
    avatar_image_path: "weareopen.png",
  },
  addressSection: {
    title: "Διεύθυνση",
    subtitle: "Μουρούζη 51, 26223 Πάτρα",
    locality: "Αχαΐα",
    country: "GR",
    region: "Πάτρα",
    postalCode: "26223",
    streetAddress: "Μουρούζη 51",
    avatar_image_path: "maps.png",
    location_map_link: "https://maps.app.goo.gl/mLnAntwqgiKbP8E97",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

//Subscriptions Page

const subscriptionsPageData = {
  plan1: {
    title: "Plan 1",
    description: "This is the description of plan 1",
    price: "10",
  },
  plan2: {
    title: "Plan 2",
    description: "This is the description of plan 2",
    price: "20",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  degrees2,
  certifications,
  experience,
  programsAdults,
  programsKids,
  about,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
  subscriptionsPageData,
};
