import React, { Component } from "react";
import { Route, Switch, HashRouter } from "react-router-dom";
import Home from "../pages/home/HomeComponent";
import Splash from "../pages/splash/Splash";
import Education from "../pages/education/EducationComponent";
import RoboticsCourses from "../pages/robotics_courses/RoboticsCourses";
import GameDesignCourses from "../pages/game_design_courses/GameDesignCourses";
import ProgrammingCourses from "../pages/programming_courses/ProgrammingCourses";
import PrintingCourses from "../pages/3dprint_courses/PrintingCourses";
import Print from "../pages/3dprint/3dprintComponent";
import Laser from "../pages/laser/laserComponent";
import Booking from "../pages/booking/bookingComponent";
import Groups from "../pages/groups/groupsComponent";
import Experience from "../pages/experience/Experience";
import Opensource from "../pages/opensource/Opensource";
import Contact from "../pages/contact/ContactComponent";
import Projects from "../pages/projects/Projects";
import Subscriptions from "../pages/subscriptions/SubscriptionsComponent";
import ProgramsAdults from "../pages/programs_adults/ProgramsAdults";
import ProgramsKids from "../pages/programs_kids/ProgramsKids";
import Error404 from "../pages/errors/error404/Error";
import Pricing from "../pages/pricing/Pricing";
import ProjectPage from "../pages/projects/ProjectPage";

export default class Main extends Component {
  render() {
    // if (settings.isSplash) {
    return (
      <div>
        <HashRouter basename="/">
          <Switch>
            <Route
              path="/"
              exact
              render={(props) => <Home {...props} theme={this.props.theme} />}
              // render={(props) => (
              //   <Splash {...props} theme={this.props.theme} />
              // )}
            />
            <Route
              path="/home"
              render={(props) => <Home {...props} theme={this.props.theme} />}
            />
            <Route
              path="/experience"
              exact
              render={(props) => (
                <Experience {...props} theme={this.props.theme} />
              )}
            />
            <Route
              path="/space"
              render={(props) => (
                <Education {...props} theme={this.props.theme} />
              )}
            />
            <Route
              path="/about"
              render={(props) => (
                <Opensource {...props} theme={this.props.theme} />
              )}
            />
            <Route
              path="/contact"
              render={(props) => (
                <Contact {...props} theme={this.props.theme} />
              )}
            />
            <Route
              path="/splash"
              render={(props) => <Splash {...props} theme={this.props.theme} />}
            />
            <Route
              exact
              path="/projects"
              render={(props) => (
                <Projects {...props} theme={this.props.theme} />
              )}
            />
            <Route
              path="/projects/:projectId"
              render={(props) => (
                <ProjectPage {...props} theme={this.props.theme} />
              )}
            />
            <Route
              path="/subscriptions"
              render={(props) => (
                <Subscriptions {...props} theme={this.props.theme} />
              )}
            />
            {/* <Route
              path="/signup"
              render={(props) => (
                <Signup {...props} theme={this.props.theme} />
              )}
            />
            <Route
              path="/signin"
              render={(props) => (
                <Signin {...props} theme={this.props.theme} />
              )}
            /> */}
            <Route
              path="/programs-kids"
              render={(props) => (
                <ProgramsKids {...props} theme={this.props.theme} />
              )}
            />
            <Route
              path="/programs-adults"
              render={(props) => (
                <ProgramsAdults {...props} theme={this.props.theme} />
              )}
            />
            <Route
              path="/pricing"
              render={(props) => (
                <Pricing {...props} theme={this.props.theme} />
              )}
            />
            <Route
              path="/robotics_courses"
              render={(props) => (
                <RoboticsCourses {...props} theme={this.props.theme} />
              )}
            />
            <Route
              path="/game_design_courses"
              render={(props) => (
                <GameDesignCourses {...props} theme={this.props.theme} />
              )}
            />
            <Route
              path="/programming_courses"
              render={(props) => (
                <ProgrammingCourses {...props} theme={this.props.theme} />
              )}
            />
            <Route
              path="/3dprint_courses"
              render={(props) => (
                <PrintingCourses {...props} theme={this.props.theme} />
              )}
            />
            <Route
              path="/3dprint"
              render={(props) => <Print {...props} theme={this.props.theme} />}
            />
            <Route
              path="/laser"
              render={(props) => <Laser {...props} theme={this.props.theme} />}
            />
            <Route
              path="/booking"
              render={(props) => (
                <Booking {...props} theme={this.props.theme} />
              )}
            />
            <Route
              path="/groups"
              render={(props) => <Groups {...props} theme={this.props.theme} />}
            />
            <Route
              path="*"
              render={(props) => (
                <Error404 {...props} theme={this.props.theme} />
              )}
            />
          </Switch>
        </HashRouter>
      </div>
    );
    // } else {
    //   return (
    //     <div>
    //       <HashRouter basename="/">
    //         <Switch>
    //           <Route
    //             path="/"
    //             exact
    //             render={(props) => <Home {...props} theme={this.props.theme} />}
    //           />
    //           <Route
    //             path="/home"
    //             render={(props) => <Home {...props} theme={this.props.theme} />}
    //           />
    //           <Route
    //             path="/experience"
    //             exact
    //             render={(props) => (
    //               <Experience {...props} theme={this.props.theme} />
    //             )}
    //           />
    //           <Route
    //             path="/education"
    //             render={(props) => (
    //               <Education {...props} theme={this.props.theme} />
    //             )}
    //           />
    //           <Route
    //             path="/opensource"
    //             render={(props) => (
    //               <Opensource {...props} theme={this.props.theme} />
    //             )}
    //           />
    //           <Route
    //             path="/contact"
    //             render={(props) => (
    //               <Contact {...props} theme={this.props.theme} />
    //             )}
    //           />
    //           {/* <Route
    //           path="/splash"
    //           render={(props) => (
    //             <Splash
    //               {...props}
    //               theme={this.props.theme}
    //             />
    //           )}
    //         /> */}
    //           <Route
    //             path="/projects"
    //             render={(props) => (
    //               <Projects {...props} theme={this.props.theme} />
    //             )}
    //           />
    //           <Route
    //             path="/subscriptions"
    //             render={(props) => (
    //               <Subscriptions {...props} theme={this.props.theme} />
    //             )}
    //           />
    //           <Route
    //             path="/signup"
    //             render={(props) => (
    //               <Signup {...props} theme={this.props.theme} />
    //             )}
    //           />
    //           <Route
    //             path="/signin"
    //             render={(props) => (
    //               <Signin {...props} theme={this.props.theme} />
    //             )}
    //           />
    //         </Switch>
    //       </HashRouter>
    //     </div>
    //   );
    // }
  }
}
