import React from "react";
import { withTranslation } from "react-i18next"; // Import withTranslation HOC
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { Fade } from "react-reveal";
import { Helmet } from "react-helmet-async";
import CheckmarkIcon from "../../assets/svgs/checkmark.svg";

import "./Pricing.css";

const Pricing = (props) => {
  const { t } = props; // Access t function from props
  return (
    <>
      <Header theme={props.theme} />
      <Helmet>
        <title>{t("pricing_title")} </title>
        <meta
          name="description"
          content="Γίνε μέλος του coLab Makerspace σήμερα."
        />
        <meta name="keywords" content="STEAM Πάτρα, STEM" />
        <link rel="canonical" href="/pricing" />
      </Helmet>
      <Fade bottom duration={2000} distance="40px">
        <div className="section-hero">
          <h1>{t("pricing_hero_title")}</h1>
        </div>
      </Fade>

      <div className="card-container">
        <Fade left duration={2000} distance="40px">
          <div className="card-day">
            <div className="daily-pass">
              <h3 className="card-title">{t("pricing_daily_pass_title")}</h3>
              <p className="card-subtitle">
                {t("pricing_daily_pass_subtitle")}
              </p>
            </div>
            <div className="price-display">
              <div className="card-amount">
                <span className="card-amount-price">€8</span>
                <span className="card-amount-choose">
                  {t("pricing_or", "ή")}
                </span>
                <span className="card-amount-price">€5</span>
                <span className="card-amount-choose-text">
                  {t("pricing_if_student")}
                </span>
              </div>
            </div>
            <ul className="card-list">
              <li className="card-list-item">
                <img
                  src={CheckmarkIcon}
                  alt="checkmark"
                  width={20}
                  height={20}
                />
                <span className="card-same-features">
                  {t("pricing_basic_equipment")}
                </span>
              </li>
              <li className="card-list-item">
                <img
                  src={CheckmarkIcon}
                  alt="checkmark"
                  width={20}
                  height={20}
                />
                <span className="card-same-features">
                  {t("pricing_equipment_loan")}
                </span>
              </li>
              <li className="card-list-item">
                <img
                  src={CheckmarkIcon}
                  alt="checkmark"
                  width={20}
                  height={20}
                />
                <span className="card-same-features">
                  {t("pricing_screen_loan")}
                </span>
              </li>
              <li className="card-list-item">
                <img
                  src={CheckmarkIcon}
                  alt="checkmark"
                  width={20}
                  height={20}
                />
                <span className="card-same-features">
                  {t("pricing_remote_work")}
                </span>
              </li>
            </ul>
          </div>
        </Fade>
        <Fade up duration={2000} distance="40px">
          <div className="card-month">
            <div className="weekly-pass">
              <h3 className="card-title">{t("pricing_monthly_pass_title")}</h3>
              <p className="card-subtitle">
                {t("pricing_monthly_pass_subtitle")}
              </p>
            </div>
            <div className="price-display">
              <div className="card-amount">
                <span className="card-amount-price-deleted">€45</span>
                <span className="card-amount-price">€40</span>
                <span className="card-amount-text">/μήνα</span>
              </div>
            </div>
            <ul className="card-list">
              <li className="card-list-item">
                <img
                  src={CheckmarkIcon}
                  alt="checkmark"
                  width={20}
                  height={20}
                />
                <span className="card-same-features">
                  {t("pricing_basic_equipment")}
                </span>
              </li>
              <li className="card-list-item">
                <img
                  src={CheckmarkIcon}
                  alt="checkmark"
                  width={20}
                  height={20}
                />
                <span className="card-same-features">
                  {t("pricing_equipment_loan")}
                </span>
              </li>
              <li className="card-list-item">
                <img
                  src={CheckmarkIcon}
                  alt="checkmark"
                  width={20}
                  height={20}
                />
                <span className="card-same-features">
                  {t("pricing_screen_loan")}
                </span>
              </li>
              <li className="card-list-item">
                <img
                  src={CheckmarkIcon}
                  alt="checkmark"
                  width={20}
                  height={20}
                />
                <span className="card-same-features">
                  {t("pricing_remote_work")}
                </span>
              </li>
              <li className="card-list-item">
                <img
                  src={CheckmarkIcon}
                  alt="checkmark"
                  width={20}
                  height={20}
                />
                <span className="discount-price">
                  {t("pricing_discount_edu")}
                </span>
              </li>
              <li className="card-list-item">
                <img
                  src={CheckmarkIcon}
                  alt="checkmark"
                  width={20}
                  height={20}
                />
                <span className="discount-price">
                  {t("pricing_discount_diy")}
                </span>
              </li>
              <li className="card-list-item">
                <img
                  src={CheckmarkIcon}
                  alt="checkmark"
                  width={20}
                  height={20}
                />
                <span>{t("pricing_priority_events")}</span>
              </li>
              <li className="card-list-item">
                <img
                  src={CheckmarkIcon}
                  alt="checkmark"
                  width={20}
                  height={20}
                />
                <span>{t("pricing_free_meeting_room")}</span>
              </li>
              <li className="card-same-features">
                <img
                  src={CheckmarkIcon}
                  alt="checkmark"
                  width={20}
                  height={20}
                />
                <span>{t("pricing_promotions")}</span>
              </li>
            </ul>
            <a
              className="button-link"
              href="https://forms.gle/wVJiSWXN1JqojReJ7"
            >
              <button className="button" type="button">
                {t("pricing_sign_up")}
              </button>
            </a>
          </div>
        </Fade>
        <Fade right duration={2000} distance="40px">
          <div className="card-month">
            <div className="student-pass">
              <h3 className="card-title">{t("pricing_student_pass_title")}</h3>
              <p className="card-subtitle">
                {t("pricing_student_pass_subtitle")}
              </p>
            </div>
            <div className="price-display">
              <div className="card-amount-student">
                <span className="card-amount-price-deleted">€30</span>
                <span className="card-amount-price">€25</span>
                <span className="card-amount-text">/μήνα</span>
              </div>
            </div>
            <ul role="list" className="card-list">
              <li className="card-list-item">
                <img
                  src={CheckmarkIcon}
                  alt="checkmark"
                  width={20}
                  height={20}
                />
                <span className="card-same-features">
                  {t("pricing_basic_equipment")}
                </span>
              </li>
              <li className="card-list-item">
                <img
                  src={CheckmarkIcon}
                  alt="checkmark"
                  width={20}
                  height={20}
                />
                <span className="card-same-features">
                  {t("pricing_equipment_loan")}
                </span>
              </li>
              <li className="card-list-item">
                <img
                  src={CheckmarkIcon}
                  alt="checkmark"
                  width={20}
                  height={20}
                />
                <span className="card-same-features">
                  {t("pricing_screen_loan")}
                </span>
              </li>
              <li className="card-list-item">
                <img
                  src={CheckmarkIcon}
                  alt="checkmark"
                  width={20}
                  height={20}
                />
                <span className="card-same-features">
                  {t("pricing_remote_work")}
                </span>
              </li>
              <li className="card-list-item">
                <img
                  src={CheckmarkIcon}
                  alt="checkmark"
                  width={20}
                  height={20}
                />
                <span className="discount-price">
                  {t("pricing_discount_edu")}
                </span>
              </li>
              <li className="card-list-item">
                <img
                  src={CheckmarkIcon}
                  alt="checkmark"
                  width={20}
                  height={20}
                />
                <span className="discount-price">
                  {t("pricing_discount_diy")}
                </span>
              </li>
              <li className="card-list-item">
                <img
                  src={CheckmarkIcon}
                  alt="checkmark"
                  width={20}
                  height={20}
                />
                <span>{t("pricing_priority_events")}</span>
              </li>
              <li className="card-list-item">
                <img
                  src={CheckmarkIcon}
                  alt="checkmark"
                  width={20}
                  height={20}
                />
                <span>{t("pricing_free_meeting_room")}</span>
              </li>
              <li className="card-same-features">
                <img
                  src={CheckmarkIcon}
                  alt="checkmark"
                  width={20}
                  height={20}
                />
                <span>{t("pricing_promotions")}</span>
              </li>
            </ul>
            <a
              className="button-student-link"
              href="https://forms.gle/wVJiSWXN1JqojReJ7"
            >
              <button className="button-student" type="button">
                {t("pricing_sign_up")}
              </button>
            </a>
          </div>
        </Fade>
        <p className="details">{t("pricing_equipment_note")}</p>
      </div>

      <Fade bottom duration={2000} distance="40px"></Fade>

      <Footer theme={props.theme} />
    </>
  );
};

export default withTranslation()(Pricing);
