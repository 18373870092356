export const SliderData = [
  {
    image: `studyS11.JPG`,
  },
  {
    image: `studyS12.JPG`,
  },
  {
    image: `studyS2.JPG`,
  },
  {
    image: `studyS3.JPG`,
  },
  {
    image: `studyS4.JPG`,
  },
  {
    image: `studyS5.JPG`,
  },
];

export const SliderData2 = [
  {
    image: `toolsS1.JPG`,
  },
  {
    image: `toolsS2.JPG`,
  },
  {
    image: `toolsS3.JPG`,
  },
  {
    image: `toolsS4.JPG`,
  },
  {
    image: `toolsS5.JPG`,
  },
  {
    image: `toolsS6.JPG`,
  },
  {
    image: `toolsS7.JPG`,
  },
];

export const SliderData3 = [
  {
    image: `teachingS11.JPG`,
  },
  {
    image: `teachingS12.JPG`,
  },
  {
    image: `teachingS2.JPG`,
  },
  {
    image: `teachingS3.JPG`,
  },
];

export const SliderData4 = [
  {
    image: `studyS11.JPG`,
  },
  {
    image: `studyS12.JPG`,
  },
  {
    image: `teachingS11.JPG`,
  },
  {
    image: `teachingS12.JPG`,
  },
];

export const HomeSliderData = [
  {
    image: `carousels/carousel1.jpeg`,
  },
  {
    image: `carousels/carousel2.jpeg`,
  },
  {
    image: `carousels/carousel3.jpeg`,
  },
];

// export const SliderData = [
//   {
//     image:
//       `../../assets/images/studyN.png`
//   },
//   {
//     image:
//       `../../assets/images/talkingN.png`
//   }
// ];

//   export const SliderData2 = [
//     {
//       image:
//         `../../assets/images/toolsN.png`
//     },
//     {
//       image:
//         `../../assets/images/printerN.png`
//     },
//     {
//       image:
//         `../../assets/images/tools2N.png`
//     }
//   ];

//   export const SliderData3 = [
//     {
//       image:
//         `../../assets/images/teachingN.png`
//     },
//     {
//       image:
//         `../../assets/images/meetingN.png`
//     }
//   ];
