import React, { Component } from "react";
import { withTranslation } from "react-i18next"; // Import withTranslation HOC
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Button from "../../components/button/Button";
import TopButton from "../../components/topButton/TopButton";
import Educations04 from "../../containers/education04/Educations04";
import "./PrintingCourses.css";
import { Fade } from "react-reveal";
import { Helmet } from "react-helmet-async";

class PrintingCourses extends Component {
  render() {
    const { t } = this.props; // Access t function from props
    const theme = this.props.theme;

    // Extract degrees from translation
    const degrees = t("degrees", { returnObjects: true });

    return (
      <div className="experience-main">
        <Header theme={theme} />
        <Helmet>
          <title>{t("3dDesignCoursesTitle")} | coLab </title>
          <meta name="description" content={t("3dDesignCoursesDescription")} />
          <meta name="keywords" content={t("3dDesignCoursesKeywords")} />
          <link rel="canonical" href="#/programs-kids" />
        </Helmet>
        <div className="basic-experience">
          <Fade bottom duration={2000} distance="40px">
            <div className="experience-heading-div">
              <div className="experience-heading-text-div">
                <h1
                  className="experience-heading-text"
                  style={{ color: theme.text }}
                >
                  {t("3dDesignCoursesTitle")}
                </h1>
                <h3 className="experience-heading-sub-text">
                  {t("3dDesignCoursesSubtitle")}
                </h3>
                <p
                  className="experience-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {t("3dDesignCoursesDescription")}
                </p>
                <p
                  className="experience-header-detail-text2 subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {t("SeptemberCoursesSubtitle")}
                </p>
                <p
                  className="experience-header-detail-text2 subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {t("SeptemberCoursesSubtitle2")}
                </p>
                <div className="button-container">
                  <Button
                    text={t("header_contact")}
                    newTab={true}
                    href={"/#/contact"}
                    theme={theme}
                  />
                </div>
              </div>
            </div>
          </Fade>
        </div>
        <Educations04 theme={this.props.theme} />
        <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

// Wrap the component with withTranslation to provide the t function
export default withTranslation()(PrintingCourses);
