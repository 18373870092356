import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import Button from "../../components/button/Button";
import "./groupsComponent.css";
import { Fade } from "react-reveal";
import { Helmet } from "react-helmet-async";
import toolsImage from "../../assets/images/teachingS2.JPG";

class Groups extends Component {
  render() {
    const { t } = this.props; // Access t function from props
    const theme = this.props.theme;
    return (
      <div className="print-main">
        <Header theme={this.props.theme} />
        <Helmet>
          <title>{t("GroupSection.title")}</title>
          <meta
            name="description"
            content="coLab Makerspace | Open Lab · Coworking Space · Study Room"
          />
          <meta
            name="keywords"
            content="Αναγνωστήριο, DIY Πάτρα, 3D Printing Πάτρα, Laser Engraving Πάτρα"
          />
          <link rel="canonical" href="/space" />
        </Helmet>
        <div className="basic-education">
          <div style={{ marginBottom: 100 }}>
            <Fade bottom duration={2000} distance="40px">
              <div className="heading-div">
                <div className="heading-img-div">
                  <img
                    src={toolsImage}
                    alt="3D Printing"
                    className="static-image"
                  />
                </div>
                <div className="heading-text-div-right">
                  <h2>{t("GroupSection.subtitle1")}</h2>
                  <div className="heading-text-div-right2">
                    {t("GroupSection.print1", { returnObjects: true }).map(
                      (text, index) => (
                        <p key={index} style={{ color: theme.secondaryText }}>
                          {text}
                        </p>
                      )
                    )}
                  </div>
                  <div className="button-container">
                    <Button
                      text={t("GroupSection.button")}
                      newTab={true}
                      href={t("GroupSection.resumeLink")}
                      theme={theme}
                    />
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
        <Footer theme={this.props.theme} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default withTranslation()(Groups);
