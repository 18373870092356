import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import "./EducationComponent.css";
import { Fade } from "react-reveal";
import ImageSlider from "../../components/imageSlider/imageSlider";
import { SliderData, SliderData2, SliderData3 } from "../../SliderData";
import { Helmet } from "react-helmet-async";

class Education extends Component {
  render() {
    const { t } = this.props; // Access t function from props
    const theme = this.props.theme;
    return (
      <div className="print-main">
        <Header theme={this.props.theme} />
        <Helmet>
          <title>{t("SpaceSection.title")}</title>
          <meta
            name="description"
            content="coLab Makerspace | Open Lab · Coworking Space · Study Room"
          />
          <meta
            name="keywords"
            content="Αναγνωστήριο, DIY Πάτρα, 3D Printing Πάτρα, Laser Engraving Πάτρα"
          />
          <link rel="canonical" href="/space" />
        </Helmet>
        <div className="basic-print">
          <div style={{ marginBottom: 100 }}>
            <Fade bottom duration={2000} distance="40px">
              <div className="heading-div">
                <div className="heading-img-div">
                  <ImageSlider slides={SliderData} />
                </div>
                <div className="heading-text-div-right">
                  <h2>{t("SpaceSection.subtitle1")}</h2>
                  <div className="heading-text-div-right2">
                    {t("SpaceSection.space1", { returnObjects: true }).map(
                      (text, index) => (
                        <p key={index} style={{ color: theme.secondaryText }}>
                          {text}
                        </p>
                      )
                    )}
                  </div>
                </div>
              </div>
            </Fade>
          </div>
          <div style={{ marginBottom: 100 }}>
            <Fade
              bottom
              duration={2000}
              distance="40px"
              style={{ marginBottom: 100 }}
            >
              <div className="heading-div mobile-reverse">
                <div className="heading-text-div-left">
                  <h2>{t("SpaceSection.subtitle2")}</h2>
                  <div className="heading-text-div-left2">
                    {t("SpaceSection.space2", { returnObjects: true }).map(
                      (text, index) => (
                        <p key={index} style={{ color: theme.secondaryText }}>
                          {text}
                        </p>
                      )
                    )}
                  </div>
                </div>
                <div className="heading-img-div">
                  <ImageSlider slides={SliderData2} />
                </div>
              </div>
            </Fade>
          </div>
          <Fade
            bottom
            duration={2000}
            distance="40px"
            style={{ marginBottom: 100 }}
          >
            <div className="heading-div">
              <div className="heading-img-div">
                <ImageSlider slides={SliderData3} />
              </div>
              <div className="heading-text-div-right">
                <h2>{t("SpaceSection.subtitle3")}</h2>
                <div className="heading-text-div-right2">
                  {t("SpaceSection.space3", { returnObjects: true }).map(
                    (text, index) => (
                      <p key={index} style={{ color: theme.secondaryText }}>
                        {text}
                      </p>
                    )
                  )}
                </div>
              </div>
            </div>
          </Fade>
        </div>
        <Footer theme={this.props.theme} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default withTranslation()(Education);
