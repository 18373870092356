import React from "react";

const GoogleMap = () => {
  return (
    <div>
      <iframe
        title="google-maps"
        height={350}
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3133.0981161972236!2d21.740117899999998!3d38.2540304!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x135e49ea49ea1fa7%3A0x9d43c07076467607!2sMourouzi%2051%2C%20Patra%20262%2023!5e0!3m2!1sen!2sgr!4v1695567053848!5m2!1sen!2sgr"
        // <<<<<<< fixes-header-in-contact-page-css-issue
        //         style={{ border: 0 }}
        //         allowfullscreen
        // =======
        //         width="550"
        //         height="350"
        //         style={{ border: 0 }}
        //         allowfullscreen=""
        // >>>>>>> master
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default GoogleMap;
