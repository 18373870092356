import React, { Component } from "react";
import { withTranslation } from "react-i18next"; // Import withTranslation HOC
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import Educations2 from "../../containers/education2/Educations2";
import "./ProgramsAdults.css";
import { Fade } from "react-reveal";
import { Helmet } from "react-helmet-async";

class ProgramsAdults extends Component {
  render() {
    const { t } = this.props; // Access t function from props
    const theme = this.props.theme;

    // Extract degrees2 from translation
    const degrees2 = t("degrees2", { returnObjects: true });

    return (
      <div className="experience-main">
        <Header theme={theme} />
        <Helmet>
          <title>{t("programsAdultsTitle")} | coLab </title>
          <meta name="description" content={t("programsAdultsDescription")} />
          <meta name="keywords" content={t("programsAdultsKeywords")} />
          <link rel="canonical" href="#/programs-adults" />
        </Helmet>
        <div className="basic-experience">
          <Fade bottom duration={2000} distance="40px">
            <div className="experience-heading-div">
              <div className="experience-heading-text-div">
                <h1
                  className="experience-heading-text"
                  style={{ color: theme.text }}
                >
                  {t("programsAdultsTitle")}
                </h1>
                <h3 className="experience-heading-sub-text">
                  {t("programsAdultsSubtitle")}
                </h3>
                <p
                  className="experience-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {t("programsAdultsDescription")}
                </p>
              </div>
            </div>
          </Fade>
        </div>
        <Educations2 theme={this.props.theme} />
        <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

// Wrap the component with withTranslation to provide the t function
export default withTranslation()(ProgramsAdults);
