import React from "react";
import "./Greeting.css";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
import { greeting } from "../../portfolio";
import { Fade } from "react-reveal";
import FeelingProud from "./FeelingProud";
import colab_banner from "../../assets/images/colab_banner.png";
import ImageSlider from "../../components/imageSlider/imageSlider";
import { HomeSliderData } from "../../SliderData";
import logo_without_tagline from "../../assets/images/logo_without_tagline.png";
import HomepageSlider from "../../components/homepageSlider/homepageSlider";

export default function Greeting(props) {
  const theme = props.theme;
  return (
    <Fade bottom duration={2000} distance="40px">
      <div className="greet-main" id="greeting">
        <div className="greeting-main">
          <div className="greeting-image-div">
            <img
              className="greeting-image"
              src={logo_without_tagline}
              style={{ width: "80%" }}
              alt="coLab Makerspace"
            />

            <HomepageSlider slides={HomeSliderData} />
          </div>
        </div>
      </div>
    </Fade>
  );
}
