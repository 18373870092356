import React, { Component } from "react";
import { withTranslation } from "react-i18next"; // Import withTranslation HOC
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import ImageOverlay from "../../components/imageOverlay/imageOverlay";
import TopButton from "../../components/topButton/TopButton";
import "./Projects.css";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Fade } from "react-reveal";

class Projects extends Component {
  constructor(props) {
    super(props);

    this.state = {
      news: [],
      loading: true,
    };
  }

  componentDidMount() {
    const apiUrl = "https://blogspot.colab-makerspace.gr/wp-json/wp/v2/new";

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched news:", data);
        const newsWithImages = data.map((item) => {
          return {
            ...item,
            imageUrl: item?.acf.embed,
            title: item.title.rendered,
          };
        });
        this.setState({
          news: newsWithImages,
          loading: false,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loading: false });
      });
  }

  render() {
    const { t } = this.props; // Access t function from props
    const theme = this.props.theme;
    return (
      <div className="projects-main">
        <Header theme={theme} />
        <Helmet>
          <title>{t("news_title")} | coLab </title>
          <meta
            name="description"
            content="Ενημερώσου για τα πιο πρόσφατα νέα του coLab."
          />
          <meta
            name="keywords"
            content="coLab, STEAM Πάτρα, Ρομποτική Πάτρα, Makerspace"
          />
          <link rel="canonical" href="/projects" />
        </Helmet>
        {this.state.loading ? (
          <div className="projects-heading-text ">{t("news_loading")}</div>
        ) : (
          <div className="repo-cards-parent-div">
            <Fade bottom duration={2000} distance="40px">
              <h1
                style={{ color: theme.text }}
                className="repo-cards-heading-text"
              >
                {t("news_title")}
              </h1>
            </Fade>
            <div className="repo-cards-div-main">
              {this.state.news.map((item) => (
                <Link to={`/projects/${item.id}`} key={item.id}>
                  <ImageOverlay data={item} />
                </Link>
              ))}
            </div>
          </div>
        )}
        <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default withTranslation()(Projects);
