import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./homepageSlider.css";

const HomepageSlider = ({ slides }) => {
  const settings = {
    fade: true,
    dots: true,
    infinite: true,
    speed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  return (
    <div className="image-slider-container">
      <Slider {...settings}>
        {slides.map((slide, index) => {
          return (
            <div>
              <img
                src={require(`../../assets/images/${slide.image}`)}
                alt="homepage slider"
              />
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default HomepageSlider;
